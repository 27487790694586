export default {
    data() {
      return {
        confirmationDialog: false,
        pathTo: null,
        oldData: {
          firstName: '',
          lastName: '',
          middleName: '',
          genderId: null,
          maritalStatusId: null,
          birthDate: null,
          personalIdentificationNumber: '',
          idCardSerialNumber: '',
          company: '',
          studentTypeId: null,
          lessonScheduleTypeId: null,         
          nationality: '',
          citizenship: '',
          educationDegreeId: null,
          emailAddress: '',
          mobileNumber: '',
          phoneNumber: '',          
        }
      }
    },
    beforeRouteLeave(to, _, next) {
      if (this.form != null && JSON.stringify(this.oldData) !== JSON.stringify(this.form)) {
        this.confirmationDialog = true
        this.pathTo = to.path
        next(false)
      } else {
        next(true)
      }
    },
    methods: {
      handleLeaveConfirmation() {
        this.confirmationDialog = false
        this.form = JSON.parse(JSON.stringify(this.oldData))
        this.$router.push({ path: this.pathTo })
      }
    }
  }
  