export default {
  methods: {
    calculateAge(date) {
      let birthday = new Date(date)
      let ageDifMs = Date.now() - birthday.getTime()
      let ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    }
  }
}
