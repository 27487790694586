<template>
  <v-tabs class="border-bottom" background-color="gray lighten-4" color="primary" v-model="model">
    <v-tab
      v-for="(item, index) in menu"
      :key="index"
      class="subtitle-2 text-transform-none"
      :to="item.link"
      :disabled="item.disabled"
    >
      {{ item.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data() {
    return {
      model: null
    }
  },
  props: {
    menu: {
      type: Array
    }
  }
}
</script>

