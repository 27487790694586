<template>
  <span>
    <router-link
      :to="item.url"
      class="a-breadcrumbs__list__item d-inline-flex align-center subtitle-2 px-2"
      :class="{
        'a-breadcrumbs__list__item--disabled': index === length - 1
      }"
    >
      {{ item.title }}
    </router-link>
    <v-icon v-if="index !== length - 1" class="gray--text text--darken-3 ml-1">
      mdi-slash-forward
    </v-icon>
  </span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    length: {
      type: Number,
      required: true
    }
  }
}
</script>
