import { weekDays, weekDaysMin, months } from '@/locales/dateRelated'

export default {
  methods: {
    getMonthFormat(isoDate) {
      const monthNumber = new Date(isoDate).getMonth()
      return months[monthNumber]
    },
    getHeaderDateFormat(isoDate) {
      const date = new Date(isoDate)
      const monthNumber = date.getMonth()
      const year = date.getFullYear()
      return `${months[monthNumber]}, ${year}`
    },
    getTitleDateFormat(isoDate) {
      const date = new Date(isoDate)
      const day = date.getDate()
      const monthNumber = date.getMonth()
      const dayOfWeek = date.getDay()
      return `${day} ${months[monthNumber]}, ${weekDays[dayOfWeek]}`
    },
    getWeekdayFormat(isoDate) {
      const dayOfWeek = new Date(isoDate).getDay()
      return weekDaysMin[dayOfWeek]
    },
    formatDate(date, isString) {
      if (!date) return null

      let formattedDate = this.dateToPickerFormat(date, isString)
      const [year, month, day] = formattedDate.split('-')
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year.padStart(4, '0')}`
    },
    dateToPickerFormat(date, isString) {
      const givenDate = isString === 'string' ? new Date(date) : date
      return (
        givenDate
          .getFullYear()
          .toString()
          .padStart(4, 0) +
        '-' +
        (givenDate.getMonth() + 1).toString().padStart(2, 0) +
        '-' +
        givenDate
          .getDate()
          .toString()
          .padStart(2, 0)
      )
    }
  }
}
