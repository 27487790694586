<template>
  <span>
    <BaseDropdown :push-bottom-by="35">
      <template v-slot:trigger="{ isDropdownOpen, toggleDropdown }">
        <button
          class="a-breadcrumbs__list__item d-inline-flex align-center subtitle-2 px-2"
          :class="{ 'a-breadcrumbs__list__item--activated': isDropdownOpen }"
          @click="toggleDropdown"
        >
          {{ item.title }}
          <v-icon class="subtitle-2 ml-1">
            mdi-chevron-down
          </v-icon>
        </button>
      </template>
      <v-list class="pt-1 pb-1">
        <v-list-item
          :to="child.url"
          :key="childIndex"
          :class="{ dropdown__parent: child.children }"
          class="body-2 gray--text text--darken-3"
          v-for="(child, childIndex) in item.children"
          dense
        >
          <v-list-item-title class="body-2 font-weight-regular">
            {{ child.title }}
          </v-list-item-title>
          <v-icon v-if="child.children" class="subtitle-2 ml-2">
            mdi-chevron-right
          </v-icon>
          <div v-if="child.children" class="dropdown__inner">
            <v-list class="pt-1 pb-1">
              <v-list-item
                :to="grandChild.url"
                :key="grandChildIndex"
                class="body-2 gray--text text--darken-3"
                v-for="(grandChild, grandChildIndex) in child.children"
                dense
              >
                <v-list-item-title class="body-2 font-weight-regular">
                  {{ grandChild.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-list-item>
      </v-list>
    </BaseDropdown>
    <v-icon v-if="index !== length - 1" class="ml-1">
      mdi-slash-forward
    </v-icon>
  </span>
</template>

<script>
import BaseDropdown from '@/shared/Common/BaseDropdown.vue'

export default {
  components: {
    BaseDropdown
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    length: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown__parent {
  position: relative;

  &:focus-within .dropdown__inner,
  &:hover .dropdown__inner {
    opacity: 1;
    visibility: visible;
  }
}

.dropdown__inner {
  opacity: 0;
  visibility: hidden;
  left: 101%;
  top: 0;
  position: absolute;
  transition: opacity 0.1s ease-in-out;
  background-color: var(--v-white-base);
  border: 1px solid var(--v-gray-lighten3);
  border-radius: 4px;
  z-index: 999;
}
</style>
