<template>
  <div class="py-3 px-4 d-flex justify-space-between align-center">
    <Breadcrumbs :breadcrumbsItems="breadcrumbs" />
  </div>
</template>

<script>
import Breadcrumbs from '@/shared/Common/Breadcrumbs/index.vue'

export default {
  props: {
    breadcrumbs: {
      type: Array
    }
  },
  components: {
    Breadcrumbs
  },
  data() {
    return {}
  }
}
</script>
