<template>
  <div id="newStudentPage" class="page">
    <div class="page__header sticky-page-header">
      <Toolbar :breadcrumbs="breadcrumbs" />
    </div>
    <div class="page__body">
      <Subheader :menu="menu" class="sticky-page-tab" />

      <General :data.sync="form" :loading="loading" @saveForm="handleSaveForm" />

      <ConfirmationPopup
        id="student-create-leave-modal"
        title="Qeydə alınmamış məlumatlar"
        :confirmText="'Çıxış et'"
        :cancelText="'İmtina et'"
        v-if="confirmationDialog"
        :dialog="confirmationDialog"
        @confirm="handleLeaveConfirmation"
        @cancel="confirmationDialog = false"
      >
        Bu səhifədən çıxmaq istədiyinizə əminsiniz? Etdiyiniz qeydlər yadda saxlanılmayacaq
      </ConfirmationPopup>
    </div>
  </div>
</template>

<script>
import API from '@/services'
import generalStudentInfoMixin from '@/mixins/generalStudentInfo'
import bus from "../../../../event-bus";
import Toolbar from '../Shared/components/Toolbar/index.vue'
import General from '../Shared/components/Form/General/index.vue'
import Subheader from '../Shared/components/Subheader/index.vue'
import ConfirmationPopup from '@/shared/Common/ConfirmationPopup.vue'

export default {
  mixins: [generalStudentInfoMixin],
  components: {
    Toolbar,
    General,
    Subheader,
    ConfirmationPopup
  },
  data() {
    return {
      menu: [
        {
          text: 'Ümumi',
          disabled: false,
          link: '#'
        },
        {
          text: 'Müqavilə',
          disabled: true,
          link: '#'
        },        
        {
          text: 'Sənədlər',
          disabled: true,
          link: '#'
        }
      ],
      breadcrumbs: [
        { title: 'Tələbələr', url: '/students' },
        { title: 'Yeni tələbə', url: '#' }
      ],
      loading: false,
      form: null
    }
  }, 
  methods: {
    handleSaveForm(form) {
      this.insertStudent(form)
    },
    insertStudent(data) {
      this.loading = true
      API.insertStudent(data)
        .then(id => {
          this.oldData = {
            ...this.form
          }
          this.$router.push({ name: 'StudentsEditGeneral', params: { id } })
          bus.$emit('notificationMessage', {
            color: 'success',
            text: 'Tələbə əlavə edildi.'
          })
        })
        .catch(() => {
          bus.$emit('notificationMessage', {
            color: 'error',
            text: 'Tələbəni əlavə etmək mümkün olmadı.'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
