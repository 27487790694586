<template>
  <v-container fluid>
    <section id="generalInfo">
      <v-form
        id="studentGeneralForm"
        ref="studentGeneralForm"
        @submit.prevent="validateForm"
        v-model="studentGeneralFormValid"
        lazy-validation
      >
        <div class="form-body border-all pa-4 ma-4 rounded">
          <v-row class="my-0">
            <v-col cols="4" class="mb-3">
              <v-text-field
                hide-details="auto"
                filled
                dense
                label="Ad*"
                v-model="form.firstName"
                :rules="rules.firstName"
              />
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-text-field
                hide-details="auto"
                filled
                dense
                label="Soyad*"
                v-model="form.lastName"
                :rules="rules.lastName"
              />
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-text-field
                hide-details="auto"
                filled
                dense
                label="Ata adı*"
                v-model="form.middleName"
                :rules="rules.middleName"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <div class="px-3 py-0">
                <v-row class="flex-column">
                  <v-col cols="12" class="py-2">
                    <span class="caption ls-0 input-color">Cinsi*</span>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-radio-group
                      v-model="form.genderId"
                      row
                      class="my-0"
                      hide-details="auto"
                      dense
                      :rules="rules.genderId"
                    >
                      <v-radio
                        :label="gender.value"
                        :value="gender.id"
                        v-for="(gender, index) in genders"
                        :key="index"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-menu
                v-model="birthDayMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="birthdateFormatted"
                    label="Doğum tarixi"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    filled
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="birthDate"
                  :month-format="getMonthFormat"
                  :header-date-format="getHeaderDateFormat"
                  :title-date-format="getTitleDateFormat"
                  :weekday-format="getWeekdayFormat"
                  :first-day-of-week="1"
                  @input="birthDayMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-text-field
                v-model="age"
                disabled
                hide-details="auto"
                filled
                dense
                label="Yaş"
              />
            </v-col>
            <v-col cols="4" class="py-0">
              <div class="px-3 py-0">
                <v-row class="flex-column">
                  <v-col cols="12" class="py-2">
                    <span class="caption ls-0 input-color">Ailə vəziyyəti</span>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-radio-group
                      v-model="form.maritalStatusId"
                      row
                      class="mt-0"
                      hide-details="auto"
                      dense
                    >
                      <v-radio
                        :label="maritalStatus.value"
                        :value="maritalStatus.id"
                        v-for="(maritalStatus, index) in maritalStatuses"
                        :key="index"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-text-field
                hide-details="auto"
                filled
                dense
                label="FIN"
                v-model="form.personalIdentificationNumber"
                v-mask="'NNNNNNN'"
                :rules="rules.personalIdentificationNumber"
              />
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-text-field
                v-model="form.idCardSerialNumber"
                hide-details="auto"
                filled
                dense
                label="Şəxsiyyət vəsiqəsinin seriya nömrəsi"
              />
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-text-field
                v-model="form.company"
                hide-details="auto"
                filled
                dense
                label="Şirkət"
              />
            </v-col>

            <v-col cols="4" class="py-0">
              <div class="px-3 py-0">
                <v-row class="flex-column">
                  <v-col cols="12" class="py-2">
                    <span class="caption ls-0 input-color">Tələbə növü</span>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-radio-group
                      v-model="form.studentTypeId"
                      row
                      class="mt-0"
                      hide-details="auto"
                      dense
                    >
                      <v-radio
                        :label="studentType.value"
                        :value="studentType.id"
                        v-for="(studentType, index) in studentTypes"
                        :key="index"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <v-col cols="4" class="mb-3">
              <v-text-field
                v-model="form.citizenship"
                hide-details="auto"
                filled
                dense
                label="Vətəndaşlıq"
              />
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-text-field
                v-model="form.nationality"
                hide-details
                filled
                dense
                label="Milliyyəti"
              />
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-select
                label="Təhsil"
                filled
                dense
                hide-details="auto"
                item-text="value"
                item-value="id"
                :items="educationDegrees"
                v-model="form.educationDegreeId"
                clearable
              />
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-text-field
                v-model="form.emailAddress"
                hide-details="auto"
                filled
                dense
                label="E-ünvan"
                :rules="rules.emailAddress"
              />
            </v-col>
            <v-col cols="4" class="mb-3">
              <v-text-field
                hide-details="auto"
                filled
                dense
                label="Mobil nömrə"
                hint="### ## ### ## ##"
                v-model="form.mobileNumber"
                v-mask="'### ## ### ## ##'"
                :rules="rules.mobileNumber"
              />
            </v-col>

            <v-col cols="4" class="mb-3">
              <v-text-field
                filled
                dense
                label="Əlaqə nömrəsi"
                v-model="form.phoneNumber"
                hide-details="auto"
              />
            </v-col>

            <v-col cols="4" class="mb-3">
              <v-select
                label="Dərs vaxtı"
                filled
                dense
                item-text="value"
                item-value="id"
                :items="lessonScheduleTypes"
                v-model="form.lessonScheduleTypeId"
                clearable
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12" class="mb-3">
              <v-select
                label="Tədris fənnləri*"
                filled
                dense
                multiple
                chips
                deletable-chips
                clearable
                required
                item-text="value"
                item-value="id"
                v-model="form.subjectIds"
                :rules="rules.subjectIds"
                :items="subjects"
                hide-details="auto"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    label
                    close
                    small
                    color="gray lighten-4"
                    class="gray--text mx-0"
                    v-if="index === 0"
                    @click:close="remove(index)"
                  >
                    <span :style="{ color: 'rgba(0, 0, 0, 0.87)' }">
                      {{ item.value }}
                    </span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption ml-2">
                    (+{{ form.subjectIds.length - 1 }} əlavə)
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </div>
        <div class="form-footer px-4 pb-4">
          <div class="d-flex justify-end">
            <v-btn
              color="success"
              depressed
              form="studentGeneralForm"
              type="submit"
              :loading="loading"
            >
              Yadda saxla
            </v-btn>
          </div>
        </div>
      </v-form>
    </section>
  </v-container>
</template>

<script>
import API from "@/services";
import datePickerFormatterMixin from "@/mixins/datePickerFormatter";
import Utils from "../../../mixins/utils";

export default {
  mixins: [datePickerFormatterMixin, Utils],
  props: {
    loading: {
      type: Boolean,
    },
    data: {
      type: Object,
    }
  },
  data() {
    return {
      birthDayMenu: false,
      birthDate: null,
      form: {
        firstName: "",
        lastName: "",
        middleName: "",
        genderId: null,
        maritalStatusId: null,
        birthDate: null,
        personalIdentificationNumber: "",
        idCardSerialNumber: "",
        company: "",
        studentTypeId: null,
        lessonScheduleTypeId: null,
        nationality: "",
        citizenship: "",
        educationDegreeId: null,
        emailAddress: "",
        mobileNumber: "",
        phoneNumber: "",
        subjectIds:[]
      },
      genders: [],
      maritalStatuses: [],
      studentTypes: [],
      educationDegrees: [],
      lessonScheduleTypes: [],
      subjects:[],
      studentGeneralFormValid: false,
      rules: {
        firstName: [(v) => !!v || "Ad tələb olunur"],
        lastName: [(v) => !!v || "Soyad tələb olunur"],
        middleName: [(v) => !!v || "Ata adı tələb olunur"],
        genderId: [(v) => !!v || "Cins tələb olunur"],
        subjectIds: [(v) => v.length > 0 || "Ən azı bir fənn seçilməlidir"],
        mobileNumber: [
          (v) => {
            if (v) {
              return v.length == 16 || "Nömrə 12 rəqəm olmalıdır";
            } else {
              return !!v || "Nömrə tələb olunur";
            }
          },
        ],
        emailAddress: [
          (v) => {
            if (v) {
              const pattern =
                /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
              return pattern.test(v) || "E-ünvan format düzgün deyil";
            }
          },
        ],
      },
      isEditMode: false,
    };
  },
  computed: {
    birthdateFormatted() {
      return this.formatedDate(this.birthDate);
    },
    age() {
      return this.birthDate ? this.calculateAge(this.birthDate) : null;
    },
  },
  watch: {
    data: {
      handler() {
        this.checkID();
      },
    },
    form: {
      handler(val) {
        if (!this.isEditMode) this.$emit("update:data", val);
      },
      deep: true,
    },
  },
  methods: {
    async checkID() {
      if (!this.data || !this.data.firstName) return;

      this.isEditMode = true;
      this.form = this.data;

      if (!this.data.birthDate) return;

      let date = new Date(this.data.birthDate);
      this.birthDate =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0");
    },
    formatedDate(date) {
      this.form.birthDate = this.birthDate;
      return this.formatDate(date, "string");
    },
    remove(index) {
      if (index >= 0) this.form.subjectIds.splice(index, 1);
    },
    validateForm() {
      const isValid = this.$refs.studentGeneralForm.validate();

      if (this.studentGeneralFormValid && isValid) {
        this.$emit("saveForm", this.form);
      }
    },
    async fetchHRMaritalStatuses() {
      this.maritalStatuses = await API.fetchHRMaritalStatuses();
    },
    async fetchStudentTypes() {
      this.studentTypes = await API.fetchStudentTypes();
    },
    async fetchHREducationDegrees() {
      this.educationDegrees = await API.fetchHREducationDegrees();
    },
    async fetchLessonScheduleTypes() {
      this.lessonScheduleTypes = await API.fetchLessonScheduleTypes();
    },
    async fetchHRGenders() {
      this.genders = await API.fetchHRGenders();
    },
    async fetchSubjects(){
      this.subjects = await API.fetchAllSubjects();
    }
  },
  created() {
    this.checkID();
    this.fetchHRMaritalStatuses();
    this.fetchStudentTypes();
    this.fetchHREducationDegrees();
    this.fetchLessonScheduleTypes();
    this.fetchHRGenders();
    this.fetchSubjects();
  },
};
</script>
