<template>
  <div ref="dropdown" class="a-dropdown">
    <slot name="trigger" :toggleDropdown="toggleDropdown" :isDropdownOpen="isDropdownOpen" />
    <portal to="dropdown">
      <div :id="computedId" class="a-dropdown__content" :style="contentStyles">
        <slot />
      </div>
    </portal>
  </div>
</template>

<script>
export default {
  props: {
    closeOnContentClick: {
      type: Boolean,
      required: false,
      default: true
    },
    autoWidth: {
      type: Boolean,
      required: false,
      default: true
    },
    pushBottomBy: {
      type: Number,
      required: false,
      default: 0
    },
    pullLeftBy: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      contentStyles: {
        top: '0px',
        left: '0px',
        width: 'auto',
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.1s ease-in-out'
      },
      isDropdownOpen: false
    }
  },
  computed: {
    computedId() {
      return Math.floor(Math.random() * 1e10 + 1).toString(16)
    }
  },
  mounted() {
    this.calculateContentPosition()
    document.addEventListener('click', this.onOutsideClick, true)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onOutsideClick)
  },
  methods: {
    calculateContentPosition() {
      const { top, left, width } = this.$refs.dropdown.getBoundingClientRect()
      this.contentStyles = {
        ...this.contentStyles,
        top: top + this.pushBottomBy + 'px',
        left: left - this.pullLeftBy + 'px',
        width: this.autoWidth ? 'auto' : width + 'px'
      }
    },
    toggleDropdown() {
      if (!this.isDropdownOpen) {
        this.calculateContentPosition()
      }
      this.isDropdownOpen = !this.isDropdownOpen
      const { opacity, visibility } = this.contentStyles
      this.contentStyles.opacity = opacity === 0 ? 1 : 0
      this.contentStyles.visibility = visibility === 'hidden' ? 'visible' : 'hidden'
    },
    closeDropdown() {
      this.isDropdownOpen = false
      this.contentStyles.opacity = 0
      this.contentStyles.visibility = 'hidden'
    },
    onOutsideClick(event) {
      const dropdownContent = document.getElementById(this.computedId)

      if (this.isDropdownOpen && dropdownContent && !dropdownContent.contains(event.target)) {
        this.closeDropdown()
      }

      if (
        this.isDropdownOpen &&
        dropdownContent &&
        dropdownContent.contains(event.target) &&
        this.closeOnContentClick
      ) {
        this.closeDropdown()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.a-dropdown {
  display: inline-block;

  &__content {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s ease-in-out;
    background-color: var(--v-white-base);
    border: 1px solid var(--v-gray-lighten3);
    border-radius: 4px;
    z-index: 8;
    will-change: opacity;

    .v-list {
      border-radius: 4px;
    }
  }
}
</style>
