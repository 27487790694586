<template>
  <div class="a-breadcrumbs">
    <ul class="a-breadcrumbs__list d-flex pl-0">
      <li class="mr-2" v-for="(item, index) in breadcrumbs" :key="index">
        <template v-if="item.children">
          <BreadcrumbItemDropdown :length="items.length" :index="index" :item="item" />
        </template>
        <template v-else>
          <BreadcrumbItem :length="breadcrumbs.length" :index="index" :item="item" />
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BreadcrumbItem from './components/BreadcrumbItem.vue'
import BreadcrumbItemDropdown from './components/BreadcrumbItemDropdown.vue'

export default {
  props: {
    breadcrumbsItems: Array
  },
  components: {
    BreadcrumbItem,
    BreadcrumbItemDropdown
  },
  computed: {
    ...mapGetters({
      items: 'Navigation/getBreadcrumbItems'
    }),
    breadcrumbs() {
      return this.breadcrumbsItems ? this.breadcrumbsItems : this.items
    }
  }
}
</script>

<style lang="scss">
.a-breadcrumbs {
  display: inline-block;

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    .a-dropdown__content {
      width: initial;
      top: 35px;
    }

    .v-icon {
      font-size: 16px !important;
      color: var(--v-gray-darken3);
    }

    &__item {
      text-decoration: none;
      outline: none;
      border-radius: 4px;
      height: 32px;
      transition: background-color 0.2s ease-in-out;
      color: var(--v-gray-darken3) !important;
      text-decoration: none;

      &:hover:not(&--activated),
      &:focus:not(&--activated) {
        background-color: var(--v-gray-lighten3);
        transition: background-color 0.2s ease-in-out;
      }

      &--disabled {
        pointer-events: none;
      }

      &--activated {
        background-color: rgba(var(--color-primary-rgb), 0.12);
        color: var(--v-primary-base) !important;

        .v-icon {
          color: var(--v-primary-base) !important;
        }
      }
    }
  }
}
</style>
